import React, { memo, useState, useMemo, createContext } from "react";
import PropTypes from "prop-types";

import { TOGGLE_SCHEDULE_BUTTONS, TOGGLE_BLOCKS, TOGGLE_SCHEDULE_FILTER_BUTTONS, TOGGLE_SCHEDULE_FILTER_VISITS } from "./languageConstants";
import Link from "next/link";
import { useRouter } from "next/router";

import { useTranslation } from "next-i18next";
import useFilteredEvents from "./useFilteredEvents";
import { useAuth } from "../../../../../utils/auth";
import { SingleWrapper } from "../../../../common/SimpleWrapper/SingleWrapper";

export const MediaElementContext = createContext({ elementBySize: null });

const ScheduleBlock = memo(
  ({
    activities,
    instructors: allInstructors,
    templates,
    allEvents,
    isShowCalendar,
    nav: { yearNav = "", monthNav = "", weekNav = "", dayNav = "" },
    setSelectedDay,
    setWeekNav,
    setMonthNav,
    setDayNav,
    activeBlockOfContent,
    setActiveBlockOfContent,
    elementBySize,
    activeHallsetGroupeId,
    timeClose,
    toggleButtons: { isMorningTime = false, isAfternoonTime = false, isEveningTime = false, isMyVisit = false },
    time,
    activity,
    instructor,
    template,
    setToggleButtons,
    setActivity,
    setTemplate,
    setInstructor,
    queryOptions,
    setEvents,
    dayStartTime,
    eveningStartTime,
  }) => {
    const [t] = useTranslation();
    const router = useRouter();

    const {
      query: { club, hallPhone },
    } = router;
    const { isAuthorized } = useAuth();
    const { events } = useFilteredEvents({
      allEvents,
      isEveningTime,
      isAfternoonTime,
      isMorningTime,
      activity,
      instructor,
      template,
      isMyVisit,
      isAuthorized,
      activeBlockOfContent,
      time,
      dayStartTime,
      eveningStartTime,
    });

    const { instructors, handleMorningClick, handleAfternoonClick, handleEveningClick, handleVisitClick } = useMemo(() => {
      return {
        instructors: allInstructors.filter(({ isInstructorVisible }) => !!isInstructorVisible),

        handleMorningClick: () =>
          setToggleButtons((prev) => ({
            ...prev,
            isMorningTime: !prev.isMorningTime,
            isAfternoonTime: false,
            isEveningTime: false,
          })),

        handleAfternoonClick: () =>
          setToggleButtons((prev) => ({
            ...prev,
            isAfternoonTime: !prev.isAfternoonTime,
            isMorningTime: false,
            isEveningTime: false,
          })),

        handleEveningClick: () =>
          setToggleButtons((prev) => ({
            ...prev,
            isEveningTime: !prev.isEveningTime,
            isAfternoonTime: false,
            isMorningTime: false,
          })),

        handleVisitClick: () =>
          setToggleButtons((prev) => ({
            ...prev,
            isMyVisit: !prev.isMyVisit,
          })),
      };
    }, [allInstructors]);

    const [showDropMenu, setShowDropMenu] = useState(false);
    const onDropdownMenuPress = (event) => {
      event.stopPropagation();
      setShowDropMenu(!showDropMenu);
    };

    const isTrains = useMemo(() => events.some(({ visitSet }) => visitSet?.length), [events]);

    const isMobile = elementBySize === "mobile";

    return (
      <MediaElementContext.Provider value={{ elementBySize }}>
        <div className="schedule-block">
          <div className="container schedule-container">
            <div className="schedule-toggle">
              <div className="schedule-toggle__button-wrapper">
                {TOGGLE_SCHEDULE_BUTTONS.map(({ slug, name, onlyDesctop }) => {
                  const activeId = activeHallsetGroupeId ? activeHallsetGroupeId : "";

                  if (!elementBySize || !onlyDesctop)
                    return (
                      <Link
                        href={{
                          pathname: `/club/${club}/schedule/${slug}/${activeId}`,
                          query: { ...(hallPhone && { hallPhone }), ...(time && { time }) },
                        }}
                        shallow={true}
                        key={slug}
                        className={
                          activeBlockOfContent === slug
                            ? "schedule-toggle__button schedule-toggle__button-active"
                            : "schedule-toggle__button"
                        }
                      >
                        {t(name)}
                      </Link>
                    );
                })}
              </div>
              <div className="filter-wrapper">
                {/* {!isMobile && ( */}
                <div className="filter-visits">
                  {TOGGLE_SCHEDULE_FILTER_VISITS.map(({ slug, placeholder, Component }) => {
                    const onPress = handleVisitClick;

                    return (
                      <Component
                        key={slug}
                        items={false}
                        initialValue={""}
                        type={slug}
                        placeholder={t(placeholder)}
                        onPress={onPress}
                        isDefault={!isAuthorized}
                        filterFull={isMobile}
                      />
                    );
                  })}
                </div>
                {/* )} */}

                <div className="filter-menu">
                  {isMobile && (
                    <div
                      className={showDropMenu ? "filter-menu__btn filter-menu__btn--active card-item" : "filter-menu__btn card-item"}
                      onClick={onDropdownMenuPress}
                    >
                      {t("filter")}
                    </div>
                  )}

                  {(!isMobile || showDropMenu) && (
                    <SingleWrapper className={isMobile ? "filter-menu__dropdown" : ""}>
                      {TOGGLE_SCHEDULE_FILTER_BUTTONS.map(({ slug, placeholder, Component }) => {
                        // if (slug === "trains" && !isMobile) return null;
                        const items =
                          (slug === "activity" && activities) ||
                          (slug === "instructors" && instructors) ||
                          (slug === "templates" && templates);
                        let onPress = undefined;
                        let initialValue = "";
                        let isDefault = false;
                        let isDayFilter = false;

                        if (slug === "morning") {
                          onPress = handleMorningClick;
                          isDayFilter = true;
                          initialValue = isMorningTime;
                        } else if (slug === "afternoon") {
                          onPress = handleAfternoonClick;
                          isDayFilter = true;
                          initialValue = isAfternoonTime;
                        } else if (slug === "evening") {
                          isDayFilter = true;
                          onPress = handleEveningClick;
                          initialValue = isEveningTime;
                        } else if (slug === "activity") {
                          onPress = setActivity;
                          initialValue = activity;
                        } else if (slug === "instructors") {
                          onPress = setInstructor;
                          initialValue = instructor;
                        } else if (slug === "templates") {
                          onPress = setTemplate;
                          initialValue = template;
                          //  || (!isMyVisit && !isTrains && activeBlockOfContent !== "list");
                        } else if (slug === "trains") {
                          onPress = handleVisitClick;
                          isDefault = !isAuthorized;
                        }

                        return (
                          <Component
                            key={slug}
                            items={items}
                            initialValue={initialValue}
                            type={slug}
                            placeholder={t(placeholder)}
                            onPress={onPress}
                            isDefault={isDefault}
                            isDayFilter={isDayFilter}
                            filterFull={true} //Mobile
                          />
                        );
                      })}
                    </SingleWrapper>
                  )}
                </div>
              </div>
            </div>
            {TOGGLE_BLOCKS.map(({ slug, Component }) => {
              if (activeBlockOfContent !== slug) return;

              const getProps = (type) => {
                const defaultDateProps = {
                  year: yearNav,
                  month: monthNav,
                };

                const defaultProps = {
                  week: weekNav,
                  day: dayNav,
                };

                if (type === "day")
                  return {
                    ...defaultDateProps,
                    ...defaultProps,
                    setNav: !!elementBySize ? setDayNav : setWeekNav,
                    setSelectedDay,
                    elementBySize,
                    queryOptions,
                    setEvents,
                  };

                if (type === "list")
                  return {
                    ...defaultDateProps,
                    ...defaultProps,
                    queryOptions,
                    setEvents,
                  };

                if (type === "week")
                  return {
                    ...defaultDateProps,
                    ...defaultProps,
                    setNav: setWeekNav,
                    setSelectedDay,
                    setActiveBlockOfContent,
                    elementBySize,
                    timeClose,
                  };

                if (type === "month")
                  return {
                    ...defaultDateProps,
                    setMonthNav,
                  };
              };

              const mapStateToProps = getProps(elementBySize ? "day" : slug);
              const noActiveVisits = isMyVisit && !isTrains;

              return (
                <Component
                  key={slug}
                  slug={slug}
                  events={events}
                  isShowCalendar={isShowCalendar}
                  noActiveVisits={noActiveVisits}
                  {...mapStateToProps}
                />
              );
            })}
          </div>
        </div>
        <style jsx global>{`
          @import "./ScheduleBlock.scss?3";
        `}</style>
      </MediaElementContext.Provider>
    );
  }
);

ScheduleBlock.propTypes = {
  activities: PropTypes.array,
  instructors: PropTypes.array,
  allEvents: PropTypes.array,
  nav: PropTypes.object,
  setSelectedDay: PropTypes.func,
  setWeekNav: PropTypes.func,
  setMonthNav: PropTypes.func,
  setDayNav: PropTypes.func,
  stateLocation: PropTypes.object,

  toggleButtons: PropTypes.object,

  setToggleButtons: PropTypes.func,
  setActivity: PropTypes.func,
  setInstructor: PropTypes.func,
};

ScheduleBlock.defaultProps = {
  activities: [],
  instructors: [],
  allEvents: [],
  nav: {},
  setSelectedDay: () => {},
  setWeekNav: () => {},
  setMonthNav: () => {},
  setDayNav: () => {},
  stateLocation: {},

  toggleButtons: {},

  setToggleButtons: {},
  setActivity: () => {},
  setInstructor: () => {},
};

export default ScheduleBlock;
